@use '@angular/material' as mat;

@mixin mat-overrides {

    // Button
    --mdc-outlined-button-container-shape: 5px !important;
    --mdc-filled-button-container-shape: 5px !important;
    --mdc-button-container-shape: 5px !important;

    .mat-mdc-tab-link-container {
      border-width: 0px !important;
    }
  
    .mdc-button {
        padding-top: 12px !important;
        padding-bottom: 12px !important;
        padding-left: 24px !important;
        padding-right: 24px !important;
        height: 44px !important;
        min-width: 110px !important;
        cursor: pointer !important;
        border-radius: 5px !important;
    }

   .mat-mdc-mini-fab{
    background-color: var(--sys-surface-container-lowest) !important;
    box-shadow: none !important;
    border : 1px solid var(--sys-outline-variant) !important;
    border-radius: 5px !important;
   }

    //Chips

    @include mat.chips-overrides((
        outline-color: var(--sys-surface-container-low)
      ));

    
    @include mat.select-overrides(
      (
        panel-background-color: var(--sys-surface-container-low)
      )
    );

    // Dialog
    --mdc-dialog-container-color: var(--sys-surface-container-lowest) !important;
    --mdc-dialog-container-shape: 5px !important;
    --mat-sidenav-container-width: 250px !important;


    // Calendar



      //Paginator
      @include mat.paginator-overrides((
        container-background-color: var(--sys-surface-container-lowest)
      ));
        
    // Card

    @include mat.card-overrides((
        container-shape: 5px,
        container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0),
        container-color: var(--sys-surface-container-lowest)
    ));

    // Menu
    @include mat.menu-overrides((
        divider-color: var(--sys-outline-variant),
        container-color: var(--sys-surface-container-lowest)
    ));

    //table
     @include mat.table-overrides((
        background-color : var(--sys-surface-container-lowest),
        row-item-outline-color : var(--sys-outline-variant)
     ));



    // Snackbar

    --mat-mdc-snack-bar-button-color: var(--sys-primary) !important;
    --mdc-snackbar-container-color: var(--sys-primary) !important;

}

@mixin light-theme-palette-overrides {
    --sys-primary: #151455;
    --sys-on-primary: #ffffff;
    --sys-primary-container: #373776;
    --sys-on-primary-container: #cfceff;

    --sys-tertiary: #39073a;
    --sys-tertiary-container: #5f2b5d;
    --sys-on-tertiary-container: #ffbff6;
    --sys-github-icon: #24292f;

}

@mixin dark-theme-palette-overrides {

  --sys-github-icon: #fff;

}

@mixin typography-overrides {
  .mat-headline-small {
    margin-bottom: 0; 
  }

}
